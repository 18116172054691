<template>
  <div v-if="match" class="match-card">
    <!-- FULL -->
    <v-card :class="{'elevation-1': !flat}" :flat="flat">
      <v-overlay
        v-if="match.complete && displayOnly && false"
        absolute
        color="white"
        :opacity="0.8"
      >
        <div class="black--text">{{ winner.name }}</div>
      </v-overlay>
      <div v-if="division.isNcaa && !match.isDual" class="d-flex justify-end">
        <lineup-assign
          :teamIds="matchIn.activeTeamIds"
          :matches="[matchIn]"
          :division="division"
          :round="round"
          :name="`${round.name} ${matchNumberDisplay}`"
          :xs="true"
          :text="true"
        ></lineup-assign>
      </div>
      <!-- Match & Court-->
      <v-card-title class="subtitle-1 font-weight-medium justify-space-between pb-0" :class="{ 'pt-0': flat || (division.isNcaa && !match.isDual), 'pt-0': displayOnly}">
        <span v-if="matchNumberDisplay">{{matchNumberDisplay}}</span>
        <span v-if="match.startTime && !hideTimes && !nextAvailable && !displayOnly" class="text-center">
          {{startTime}}
          <div class="caption grey--text" v-if="!isToday && tournament.isOneDay">{{startTimeObj.format('dddd')}}</div>
        </span>
        <span v-if="match.court">Court: {{match.court}}</span>
      </v-card-title>
      <!-- DISPLAY -->
      <v-window v-model="window" touchless>
        <v-window-item :value="1">
          <v-container fluid class="grow" :class="{ 'pa-1': xs || displayOnly, 'pb-0': volleyWrite }">
            <!-- Teams -->
            <v-row dense>
              <v-col cols="12" v-if="(diffSettings || showMatchSettings) && !(pool && pool.locked)">
                <v-alert
                  type="info"
                  :class="$vuetify.breakpoint.smAndDown ? 'body-2' : 'subtitle-1'"
                >
                  {{matchDescription}}.
                </v-alert>
              </v-col>
              <v-col cols="12">
                <v-data-table
                  ref="table"
                  :headers="headers"
                  :items="teams"
                  hide-default-footer
                  :mobile-breakpoint="0"
                  :hide-default-header="skinny"
                  class="elevation-1 teams-table"
                >
                  <template v-slot:body="{ items }">
                    <tbody>
                      <template v-for="(item, i) in items" >
                      <team-row :key="i"
                        :item="item"
                        :match="match"
                        :games="games"
                        :editing="editing"
                        :winLoss="winLoss"
                        :division="division"
                        :teamColorsInverse="teamColorsInverse"
                        :ref="item.map"
                        :bracketCard="bracketCard"
                        :hideSeeds="hideSeeds"
                        :active="match.status === 'Started' && !(pool && pool.locked)"
                        :lineupLock="lLock"
                        :vw="vw"
                      >
                      </team-row>
                      <v-progress-linear
                        :key="`${i}-progress`"
                        v-if="match.status === 'Started' && i < (items.length - 1) && !(pool && pool.locked)"
                        height="1"
                        stream color="success"
                        buffer-value="0"
                        reverse
                      ></v-progress-linear>
                      </template>
                    </tbody>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
            <v-row dense  v-if="outrageous">
              <v-col cols="12" class="red--text text-right" >
                Really??? {{outrageous}} points? Wow, that's impressive!
              </v-col>
            </v-row>
            <!-- W/L Button -->
            <v-row dense v-if="editing && !locked">
              <v-btn
                text
                small
                color="color3"
                class="tiny ma-0"
                @click="winLoss = !winLoss"
              >{{winLoss ? 'Scores' : 'W/L'}}</v-btn>
              <v-spacer></v-spacer>
              <v-btn
                v-if="matchAdmin && status && status !== 'Complete'"
                text
                small
                color="color3"
                class="tiny ma-0"
                @click="askComplete = true"
              >finish as is</v-btn>
              <v-btn
                v-if="showResetGames"
                text
                small
                color="color3"
                class="tiny ma-0"
                @click="resetGames"
              >reset games</v-btn>
            </v-row>
            <!-- Reffing Team -->
            <v-row dense align="center" justify="space-between" v-if="!hideRefs && !skinny">
              <v-col class="text-right ref" v-if="refDisplay.name">
                <v-avatar
                  v-if="refDisplay.slot"
                  size="24"
                  :color="teamColorsInverse[refDisplay.slot - 1]"
                  class="mr-1"
                >
                  <span class="white--text">{{refDisplay.slot}}</span>
                </v-avatar>
                <strong>Ref: </strong>
                {{refDisplay.name}}
              </v-col>
            </v-row>
            <v-row dense v-if="volleyWrite && view.adminAndRefs">
              <v-col cols="6" class="pa-0">
                <v-btn color="color3" text x-small @click.stop="vw = !vw">
                  {{ vw ? 'hide' : 'show' }} VolleyWrite
                </v-btn>
              </v-col>
              <v-col cols="6" class="pa-0 text-end">
                <span v-if="vw" class="xsmall">Match Id: {{this.bracket ? 'B' : 'P'}}{{matchIn.id}}</span>
              </v-col>
            </v-row>
            <v-row dense v-if="user && user.vblx">
              <div class="xsmall text-end grey--text">Match Id: {{match.id}}</div>
            </v-row>
          </v-container>
        </v-window-item>
        <!-- ADMIN FUNCTIONS -->
        <template v-if="matchAdmin">
          <!-- ADMIN FUNCTION BUTTONS-->
          <v-window-item :value="2">
            <v-container fluid class="grow" :class="{'pb-0': !!match.status}">
              <v-row dense justify="center">
                <!-- START TIME -->
                <v-col cols="12" sm="6">
                  <v-btn
                    block
                    color="color3 color3Text--text"
                    @click.stop="edit(3)" small
                  >
                    {{match.startTime ? 'Update the' : 'Assign a'}} start time
                  </v-btn>
                </v-col>
                <!-- COURT -->
                <v-col cols="12" sm="6">
                  <v-btn
                    block
                    color="color3 color3Text--text"
                    @click.stop="edit(4)" small
                  >{{match.court ? 'Update the' : 'Assign a'}} court</v-btn>
                </v-col>
                <!-- REF -->
                <v-col cols="12" sm="6">
                  <v-btn
                    block
                    color="color3 color3Text--text"
                    @click.stop="edit(bracketCard ? 6 : 5)" small
                  >{{match.refTeam ? 'Update the' : 'Assign a'}} ref</v-btn>
                </v-col>
                <!-- MATCH SETTINGS -->
                <v-col cols="12" sm="6" v-if="bracket || (user && user.vbl && !!match.id)">
                  <v-btn
                    block
                    color="color3 color3Text--text"
                    @click.stop="edit(9)" small
                  >Update match settings</v-btn>
                </v-col>
                <!-- TEAM SWAP -->
                <v-col cols="12" sm="6" v-if="match.type === 'pool' && tournament.isLeague && ((awayTeam && homeTeam) || (user && user.vbl))">
                  <v-btn
                    block
                    color="color3 color3Text--text"
                    @click.stop="edit(10)" small
                  >Swap a team</v-btn>
                </v-col>
                <!-- DELETE MATCH -->
                <v-col cols="12" sm="6" v-if="tournament.isLeague || match.isTiebreak || round.freePlay">
                  <v-btn
                    block
                    color="red white--text"
                    @click.stop="edit(7)" small
                  >Delete Match</v-btn>
                </v-col>
                <!-- Score Sheet Print -->
                <v-col cols="12" sm="6" v-if="tournament.isNcaa && view.adminAndRefs">
                  <match-print
                    :tournament="tournament"
                    :division="division"
                    :round="round"
                    :matches="[match]"
                    :homeTeam="homeTeam"
                    :awayTeam="awayTeam"
                    :pool="pool"
                    :block="true"
                  ></match-print>
                </v-col>
              </v-row>
              <v-row dense v-if="match.status && bracketCard">
                <v-col class="text-right" cols="12" >
                  <v-btn color="color3" text class="xsmall" @click.stop="window = 8">unplay match</v-btn>
                </v-col>
              </v-row>
              <div v-if="user && user.vbl" class="xsmall text-end grey--text">Match Id: {{match.id}}</div>
            </v-container>
          </v-window-item>
          <!-- TIME ASSIGNMENT -->
          <v-window-item :value="3">
            <v-card-text>
              <v-card color="grey lighten-3">
                <v-card-title class="title">
                  Match Time Assignment
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text class="pb-0">
                  <date-time-picker
                    :datetime.sync="match.startTime"
                    label="Start"
                  ></date-time-picker>
                </v-card-text>
                <v-card-actions class="pa-0">
                  <v-spacer></v-spacer>
                  <v-btn color="color3" class="xsmall" text @click.stop="match.startTime = null">clear time</v-btn>
                </v-card-actions>
              </v-card>
            </v-card-text>
          </v-window-item>
          <!-- COURT ASSIGNMENT -->
          <v-window-item :value="4">
            <v-card-text>
              <v-card color="grey lighten-3">
                <v-card-title class="title">
                  Court Assignment
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                  <v-combobox
                    v-model="match.court"
                    :search-input.sync="match.court"
                    :items="tournament.courts"
                    :label="`${match.court ? 'Update the' : 'Assign a'} court`"
                    persistent-hint
                    hint="Select a court or enter a new one"
                    color="color3"
                    clearable
                  ></v-combobox>
                </v-card-text>
              </v-card>
            </v-card-text>
          </v-window-item>
          <!-- POOL REF ASSIGNMENT -->
          <v-window-item :value="5">
            <v-card-text>
              <v-card color="grey lighten-3">
                <v-card-title class="title">
                  Ref Assignment
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text v-if="!bracketCard" class="text-right">
                  <v-window v-model="poolRefWindow" touchless>
                    <v-window-item :value="1">
                      <v-autocomplete
                        :items="otherPoolTeams"
                        item-text="name"
                        item-value="id"
                        return-object
                        v-model="match.refTeam"
                        label="Pool Teams"
                        hint="Select a pool team to ref"
                        persistent-hint
                        color="color3"
                        clearable
                      ></v-autocomplete>
                      <v-btn color="color3" text small class="pa-0 ma-0" @click.stop="poolRefWindow = 2">Choose from Other teams</v-btn>
                    </v-window-item>
                    <v-window-item :value="2">
                      <v-autocomplete
                        :items="nonPoolTeams"
                        item-text="name"
                        return-object
                        v-model="match.manualRefTeam"
                        label="Teams"
                        hint="Select a team to ref"
                        persistent-hint
                        color="color3"
                        clearable
                      ></v-autocomplete>
                      <v-btn color="color3" text small class="pa-0 ma-0" @click.stop="poolRefWindow = 1">Choose from Pool teams</v-btn>
                    </v-window-item>
                  </v-window>
                </v-card-text>
              </v-card>
            </v-card-text>
          </v-window-item>
          <!-- REF ASSIGNMENT -->
          <v-window-item :value="6">
            <v-card-text>
              <v-card color="grey lighten-3">
                <v-card-title class="title">
                  Ref Assignment
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                  <v-window v-model="bracketRefWindow" touchless>
                    <v-window-item :value="1">
                      <v-autocomplete
                        :items="nonPoolTeams"
                        item-text="name"
                        return-object
                        v-model="match.refTeam"
                        :label="`${match.refTeam ? 'Update the' : 'Assign a'} ref`"
                        hint="Select a team to ref"
                        persistent-hint
                        color="color3"
                        clearable
                      ></v-autocomplete>
                      <div class="text-end">
                        <v-btn color="color3" text small class="pa-0 ma-0" @click.stop="bracketRefWindow = 2">Use Match Loser/Winner</v-btn>
                      </div>
                    </v-window-item>
                    <v-window-item :value="2">
                      <v-autocomplete
                        :items="otherMatchesAsOptions"
                        v-model="refFeeder"
                        label="Select a Match"
                        color="color3"
                        clearable
                      ></v-autocomplete>
                      <v-autocomplete
                        :items="[
                          { text: 'Loser', value: 1},
                          { text: 'Winner', value: -1}
                        ]"
                        v-model="refWL"
                        label="Winner or Loser?"
                        color="color3"
                        clearable
                      ></v-autocomplete>
                      <div class="text-end">
                        <v-btn color="color3" text small class="pa-0 ma-0" @click.stop="bracketRefWindow = 1">Choose from teams</v-btn>
                      </div>
                    </v-window-item>
                  </v-window>
                </v-card-text>
              </v-card>
            </v-card-text>
          </v-window-item>
          <!-- MARK COMPLETE -->
          <v-window-item :value="7">
            <v-card-text>
              <v-card color="grey lighten-3">
                <v-card-title class="title">
                  Delete Match
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text class="text-center subtitle-1">
                  <v-row dense>
                    <v-col cols="12" class="title">
                      Are you sure?
                    </v-col>
                    <v-col cols="12">
                      <v-btn color="red white--text" :loading="loading" @click.stop="deleteMe">Delete</v-btn>
                      <v-btn color="error" text :disabled="loading" @click.stop="window = 2">cancel</v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-card-text>
          </v-window-item>
          <!-- UNPLAY -->
          <v-window-item :value="8">
            <v-card-text class="text-center subtitle-1">
              <v-row dense >
                <v-col cols="12">
                  <v-alert type="warning" :value="true" prominent text>
                    Unplaying the match will also unplay all subsequent matches and undo any results
                  </v-alert>
                </v-col>
              </v-row>
              <v-row dense >
                <v-col cols="12">
                  Are you sure?
                </v-col>
                <v-col cols="12">
                  <v-btn color="color3 color3Text--text" :loading="loading" @click.stop="unplay">Unplay</v-btn>
                  <v-btn color="error" text @click.stop="window = 2">cancel</v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-window-item>
          <!-- Match Settings -->
          <v-window-item :value="9">
            <v-card-text>
              <v-card color="grey lighten-3">
                <v-card-title class="title">
                  Match Settings
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text v-if="settings && window === 9">
                  <match-settings
                    :setting="settings"
                    :edit="true"
                    :bracket="!!bracket"
                    :round="match.round"
                  ></match-settings>
                  <v-expand-transition>
                    <div v-if=" bracket && (dirtySettings || diffSettings)">
                      <v-row class="pl-3">
                        <div>Additionally apply this to:</div>
                      </v-row>
                      <v-row class="pl-3">
                        <v-checkbox
                          hide-details
                          label="Entire Bracket"
                          v-model="applyToAll"
                          color="success"
                          :class="`${xs ? 'shrink' : null}  mr-3`"
                        ></v-checkbox>
                        <v-slide-x-transition>
                          <v-checkbox
                            hide-details
                            label="This round"
                            v-model="applyToRound"
                            :disabled="applyToAll"
                            color="success"
                            :class="`${xs ? 'shrink' : null}  mr-3`"
                          ></v-checkbox>
                        </v-slide-x-transition>
                        <v-slide-x-transition>
                          <v-checkbox
                            hide-details
                            label="And all future rounds"
                            v-model="applyToFuture"
                            :disabled="applyToAll || !applyToRound"
                            color="success"
                            :class="`${xs ? 'shrink' : null}  mr-3`"
                          ></v-checkbox>
                        </v-slide-x-transition>
                      </v-row>
                    </div>
                  </v-expand-transition>
                </v-card-text>
              </v-card>
            </v-card-text>
          </v-window-item>
          <!-- Team Swap -->
          <v-window-item :value="10">
            <v-card-text>
              <team-swapper
                v-if="window === 10"
                :tournament="tournament"
                :division="division"
                :pool="pool"
                :match="match"
                @team-swapped="$emit('match-saved'); cancelEdit(); window = 1;"
              ></team-swapper>
            </v-card-text>
          </v-window-item>
        </template>
      </v-window>
      <!-- ADMIN ACTION BUTTONS -->
      <template v-if="!skinny">
        <v-divider v-if="!(lockOnSubmit && matchIn.complete && !view.adminAndRefs)"></v-divider>
        <v-card-actions v-if="((matchAdmin && !publicView) || (publicScoring && !locked)) && (!locked || streaming)">
          <!-- leftAdminFab -->
          <v-fab-transition>
            <v-btn
              v-if="leftAdminFab && !locked && !(lockOnSubmit && matchIn.complete && !view.adminAndRefs)"
              :key="leftAdminFab.key"
              fab
              small
              class="ma-0 mr-2"
              :color="leftAdminFab.color"
              @click.stop="leftAdminFab.click"
              :disabled="leftAdminFab.disabled"
            >
              <v-icon small>{{leftAdminFab.icon}}</v-icon>
            </v-btn>
          </v-fab-transition>
          <!-- Move Right -->
          <v-fab-transition>
            <v-btn
              v-if="window === 1 && editing && matchAdmin"
              fab small
              color="color3Text color3--text"
              @click.stop="window++"
              :disabled="dirty || loading"
            >
              <v-icon small>fas fa-caret-right</v-icon>
            </v-btn>
          </v-fab-transition>
          <!-- LIVE STREAMING -->
          <add-stream-url-dialog
            v-if="streaming"
            :poolId="poolId"
            :bracketId="bracket ? bracket.id : null"
            :matchNumber="matchIn.number"
            :complete="matchIn.complete"
            :status="matchIn.status"
          ></add-stream-url-dialog>
          <!-- LIVE SCORING -->
          <v-fab-transition>
            <live-scoring-dialog
              v-if="division && round && ((!isComplete && matchIn.games && bothTeams && !noLive) || (matchProps && matchProps.id === matchIn.id))"
              :disabled="dirty || loading"
              :matchIn="matchIn"
              :division="division"
              :round="round"
              @ended="$emit('match-saved')"
            ></live-scoring-dialog>
          </v-fab-transition>
          <v-spacer></v-spacer>
          <!-- VMIX -->
          <v-btn color="color3" text x-small v-if="vmix" @click.stop="showVMix = !showVMix">vMix</v-btn>
          <!-- Save Button -->
          <v-fab-transition>
            <v-btn
              v-if="dirty"
              fab small
              color="color3 color3Text--text"
              :disabled="loading || !!outrageous"
              :loading="loading"
              @click.stop="onSaveClick"
              class="mr-2"
            >
              <v-icon small>fs fa-save</v-icon>
            </v-btn>
          </v-fab-transition>
          <!-- rightAdminFab -->
          <v-fab-transition>
            <v-btn
              v-if="rightAdminFab && !locked"
              :key="rightAdminFab.key"
              fab
              small
              class="ma-0"
              :color="rightAdminFab.color"
              @click.stop="rightAdminFab.click"
              :disabled="loading"
            >
              <v-icon small>{{rightAdminFab.icon}}</v-icon>
            </v-btn>
          </v-fab-transition>

        </v-card-actions>
        <v-card-actions v-if="jumpTo" class="justify-space-between">
          <v-btn
            color="color3"
            text
            small
            :to="jumpTo.to"
            :class="{ 'mt-3': xs}"
          >
            <v-icon class="mr-3">fas fa-caret-right</v-icon>
            {{jumpTo.text}}
          </v-btn>
          <span v-if="user && user.vbl" class="xsmall">Match Id: {{matchIn.id}}</span>
        </v-card-actions>
      </template>
      <!-- APPEARS INCOMPLETE -->
      <v-expand-transition>
        <div
          v-if="askComplete"
          class="d-flex transition-fast-in-fast-out color3 v-card--reveal headline color3Text--text"
          style="height: 100%;"
        >
          <v-container fluid>
            <v-row dense >
              <v-col cols="12">
                The match appears incomplete.
              </v-col>
              <v-col cols="12">
                Is it over?
              </v-col>
              <v-col cols="12">
                <v-btn
                  color="color3Text color3--text"
                  class="mb-2"
                  block @click.stop="saveEdit"
                  :loading="loading"
                >
                  No{{dirty ? ', just updating' : ''}}
                </v-btn>
                <v-btn color="color3Text color3--text" block @click.stop="completeMatch" :loading="loading">It's over</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </v-expand-transition>
      <v-overlay :value="true" color="white" absolute v-if="match.isForfeit" style="z-index: 4;">
        <div class="error--text title"><v-icon color="error" class="mr-2">fas fa-medkit</v-icon> Forfeit</div>
      </v-overlay>
      <v-expand-transition>
        <div v-if="showVMix">
          {{vmixText}}
          <v-btn color="success" text x-small @click.stop="copyToClipboard(vmixText)">
            <v-icon class="xsmall">fas fa-copy</v-icon>
          </v-btn>
        </div>
      </v-expand-transition>
    </v-card>
  </div>
</template>

<script>
import RouteMixin from '@/Mixins/RouteMixin'
import RoundMixin from '@/Mixins/RoundMixin'
import Match from '@/classes/Match'
import flatten from '@/helpers/ArrayFlatten'
import { mapGetters } from 'vuex'
import DateTimePicker from '@/components/Utils/DateTimePicker.vue'
import MatchSettings from '@/components/Tournament/RoundSettings/MatchSettings'
import MatchSettingsJs from '@/classes/MatchSettings'
import LiveScoringDialog from '@/components/Tournament/LiveScoring/LiveScoringDialog.vue'
import TeamRow from '@/components/Tournament/Match/TeamRow'
import PoolPlayOptionDescriptor from '@/classes/PoolPlayOptionDescriptor'
import moment from 'moment'
import { api } from '@/classes/_URL'
import CopyToClipboard from '@/helpers/CopyToClipboard'
import { routeHelper } from '@/classes/HelperFunctions'
import MatchDescriptor from '@/classes/MatchDescriptor'
import Game from '@/classes/Game'
import { firstBy } from 'thenby'
import MinifyDto from '@/helpers/MinifyDto'
const AddStreamUrlDialog = () => import('@/components/LiveStream/AddStreamUrlDialog')
const LineupAssign = () => import('@/components/Team/Lineups/LineupAssign.vue')
const TeamSwapper = () => import('@/components/Tournament/Match/TeamSwapper')
const MatchPrint = () => import('@/components/Printing/MatchPrint')

export default {
  mixins: [RouteMixin, RoundMixin],
  props: [
    'division',
    'round',
    'pool',
    'bracket',
    'matchIn',
    'editAll',
    'skinny',
    'bracketCard',
    'showMap',
    'editAllN',
    'mode',
    'showFooter',
    'lineText',
    'newB',
    'mapper',
    'map',
    'flat',
    'hideLive',
    'jump',
    'showMatchSettings',
    'poolLeague',
    'lineupLock',
    'displayOnly'
  ],
  data () {
    return {
      loading: false,
      winLoss: false,
      editableMatch: null,
      liveScoring: false,
      homeLine: null,
      awayLine: null,
      toLine: null,
      window: 1,
      editing: false,
      all: false,
      swapMe: null,
      swapWith: null,
      poolRefWindow: 1,
      bracketRefWindow: 1,
      askComplete: false,
      settings: null,
      settingsOg: null,
      showVMix: false,
      applyToAll: false,
      applyToRound: false,
      applyToFuture: false,
      refFeeder: null,
      refWL: 1,
      vw: false,
      refreshKey: 1,
      timeOut: null
    }
  },
  computed: {
    ...mapGetters([
      'teamColorsInverse',
      'theme',
      'admin',
      'view',
      'publicView',
      'tournament',
      'getTeam',
      'getDivision',
      'user',
      'matchProps',
      'userTeams'
    ]),
    lineupLock2 () {
      return this.refreshKey && this.division && this.division.lineupLockObj(this.match.startTime, this.view, this.userTeams)
    },
    lLock () {
      return this.lineupLock || this.lineupLock2
    },
    volleyWrite () {
      return (this.tournament && this.tournament.props && this.tournament.props.includes('volleyWrite')) || (this.division && this.division.props && this.division.props.includes('volleyWrite'))
    },
    lockOnSubmit () {
      return (this.tournament && this.tournament.props && this.tournament.props.includes('lockOnSubmit')) || (this.division && this.division.props && this.division.props.includes('lockOnSubmit'))
    },
    otherMatchesAsOptions () {
      return this.bracket ? this.bracket.allMatches.filter(f => f.number !== this.match.number && f.displayNumber > 0).map(m => {
        return {
          text: m.displayNumber,
          value: m.number
        }
      }).sort(firstBy('value')) : []
    },
    xs () {
      return this.$vuetify.breakpoint.xsOnly
    },
    vmix () {
      return this.matchAdmin && this.tournament.props.includes('vmix')
    },
    apiBase () {
      return api
    },
    vmixText () {
      return `${this.apiBase}/matches/${this.matchIn.id}/vmix?bracket=${!!this.bracketCard}`
    },
    nextAvailable () {
      return this.match.court && this.match.court.toLowerCase().includes('avail')
    },
    jumpTo () {
      return this.tournament && this.division && this.round && this.jump && routeHelper(this.tournament, this.division, this.round, this.matchIn)
    },
    streaming () {
      return this.tournament.props.includes('streaming')
    },
    noLive () {
      return this.hideLive || (this.tournament.props.includes('noLive') && !this.view.admin)
    },
    isComplete () {
      return this.match.complete
    },
    courtOrder () {
      return this.hideTimes && this.match.courtOrder
    },
    isRef () {
      return this.user && this.tournament && this.tournament.refIds.includes(this.user.id)
    },
    matchAdmin () {
      return (this.admin || this.isRef) && !this.view.public && !this.displayOnly
    },
    dId () {
      return this.division.id
    },
    rId () {
      return this.round.id
    },
    roundJProps () {
      return this.$store.getters.getRoundJProps(this.rId)
    },
    hideTimes () {
      const r = this.$store.getters.getRound(this.rId)
      return !!(r && r.hideTimes)
    },
    hideSeeds () {
      return !!((this.roundJProps && this.roundJProps.noSeeds) || this.$store.getters.getDivisionJProp(this.dId, 'hideSeed'))
    },
    hideRefs () {
      return !!(this.roundJProps && this.roundJProps.noRefs)
    },
    timeAdjustment () {
      const a = this.division && this.round && this.division.props.find(f => f.startsWith(`timeAdjust-r${this.round.number}`))
      const b = a && a.replace(`timeAdjust-r${this.round.number}-`, '')
      return b
    },
    startTimeObj () {
      if (!this.match.startTime) return null
      const s = moment(this.match.startTime)
      if (this.timeAdjustment) {
        const n = this.timeAdjustment.startsWith('n')
        const m = +this.timeAdjustment.replace('n', '')
        if (n) {
          s.subtract(m, 'm')
        } else {
          s.add(m, 'm')
        }
      }
      return s
    },
    startTime () {
      if (!this.startTimeObj) return null
      const s = this.startTimeObj
      const t = s.format('h:mm A')
      const d = s.format('ddd h:mm A')
      return this.tournament.isOneDay ? t : d
    },
    isToday () {
      return this.match.startTime && moment().isSame(this.match.startTime, 'day')
    },
    otherPoolTeams () {
      if (this.bracketCard) return []
      return this.pool.teams.filter(f => !this.idSet.has(f.teamId)).map(m => {
        const t = this.getTeam(m.teamId)
        if (t) m.name = t.name
        return m
      })
    },
    allPoolTeams () {
      return this.pool.teams.map(m => {
        const t = this.getTeam(m.teamId)
        if (t) m.name = t.name
        return m
      })
    },
    awayTeam () {
      return this.match.awayTeam ? this.getTeam(this.match.awayTeam.teamId) : null
    },
    homeTeam () {
      return this.match.homeTeam ? this.getTeam(this.match.homeTeam.teamId) : null
    },
    refTeam () {
      return this.match.refTeam ? this.getTeam((this.match.refTeam.teamId || this.match.refTeam.id)) : null
    },
    bothTeams () {
      return !this.teams.find(f => f.holder)
    },
    nonPoolTeams () {
      const teams = [...this.tournament.activeRefs.filter(f => !this.idSet.has(f.id))]
      teams.push(...this.tournament.activeTeams.filter(f => !this.idSet.has(f.id)))
      return teams
    },
    idSet () {
      const ids = new Set()
      this.match.homeTeam && ids.add(this.match.homeTeam.teamId)
      this.match.awayTeam && ids.add(this.match.awayTeam.teamId)
      return ids
    },
    matchNumberDisplay () {
      if (this.tournament && this.tournament.isLeague && !this.poolLeague) return null
      if (this.match.isDual) return `Pair ${(this.match.dualMatchN || 0) + this.division.dualAdj}`
      if (this.match.title) return this.match.title
      const n = this.bracketCard ? this.match.displayNumber : this.match.number
      return n ? `Match ${n}` : 'Bye'
    },
    locked () {
      return this.tournament.locked || this.division.complete || (this.round && this.round.locked) || (this.bracketCard && this.bracket.locked) || (!this.bracketCard && this.pool.locked)
    },
    liveMatch () {
      return this.matchIn.id && this.tournament.matches.find(f => f.id === this.matchIn.id)
    },
    match () {
      return this.editing ? this.editableMatch : this.liveMatch || this.matchIn
    },
    games () {
      return this.editing ? this.editableMatch.games : this.liveMatch ? this.liveMatch.publicGames : this.matchIn.publicGames
    },
    refDisplay () {
      return this.match.manualRefTeam ? {
        name: this.match.manualRefTeam.name,
        lnames: this.match.manualRefTeam.lastNames
      } : {
        name: this.refTeam ? this.refTeam.name : this.pool ? null : this.courtOrder && this.match.courtOrder > 1 ? `Loser match ${this.match.courtOrder - 1}` : this.match.refMap,
        slot: this.refTeam && !this.tournament.isLeague ? this.match.refTeam.slot : null,
        lastNames: this.refTeam ? this.refTeam.lastNames : null
      }
    },
    leftAdminFab () {
      if (this.window === 1) {
        return {
          icon: 'fas fa-pencil-alt',
          color: 'color3 color3Text--text',
          click: this.edit,
          disabled: this.editing || !this.bothTeams,
          key: 1
        }
      } else {
        return {
          icon: 'fas fa-caret-left',
          color: 'color3Text color3--text',
          click: () => {
            this.window = this.window > 2 ? 2 : 1
            if (this.window === 1) {
              this.cancelEdit()
            }
          },
          key: 2
        }
      }
    },
    rightAdminFab () {
      if (this.editing || this.dirty) {
        return {
          icon: 'fas fa-times',
          color: 'red white--text',
          click: this.cancelEdit,
          key: 1
        }
      } else if (this.window === 1 && this.matchAdmin) {
        return {
          icon: 'fas fa-user-shield',
          color: 'color3 color3Text--text',
          click: () => { this.window = 2 },
          key: 2
        }
      }
      return null
    },
    status () {
      return this.match.status
    },
    isBye () {
      return this.match.isBye
    },
    homeSeed () {
      return this.match.homeTeam ? this.match.homeTeam.seed : this.match.homeSeed || ''
    },
    homeMap () {
      return this.match.homeMap
    },
    awaySeed () {
      return this.match.awayTeam ? this.match.awayTeam.seed : this.match.awaySeed || ''
    },
    awayMap () {
      return this.match.awayMap
    },
    headers () {
      const h = [{ text: 'Team', align: 'left', sortable: false }]
      let i = 1
      this.games.forEach(g => {
        h.push({ text: `Set ${i++}`, align: 'center', sortable: false })
      })
      return h
    },
    dirty () {
      if (this.window === 9) return this.dirtySettings
      if (this.window === 6 && this.bracketRefWindow === 2) return !!this.refFeeder
      return this.matchIn && this.editableMatch && JSON.stringify(this.matchIn.dto) !== JSON.stringify(this.editableMatch.dto)
    },
    dirtyObj () {
      return this.matchIn && this.editableMatch && {
        a: JSON.stringify(this.matchIn.dto),
        b: JSON.stringify(this.editableMatch.dto)
      }
    },
    dirtySettings () {
      return this.matchIn.type !== 'bracket' || ((this.settings && this.settings.dirty) || (!!this.applyTo))
    },
    dto () {
      return this.editableMatch.dto
    },
    outrageous () {
      if (this.editableMatch && this.editableMatch.dirty) {
        const scores = flatten(this.editableMatch.games.map(g => {
          return [g.home, g.away]
        }))
        let x = scores.find(s => s > 99)
        if (x) return x

        x = scores.find(s => s < 0)
        if (x) return x
      }
      return null
    },
    winner () {
      return this.teams.filter(f => f.winner)
    },
    teams () {
      const t = []
      // home
      if (this.match.homeTeam) {
        t.push({
          slots: [this.match.homeTeam.slot],
          teams: [this.homeTeam],
          winner: this.match.winner === 'home',
          map: 'home',
          seed: this.homeSeed,
          logo: this.useLogos && this.homeTeam && this.homeTeam.logo
        })
      } else if (this.match.homeTeamIds.length) {
        t.push({
          kob: true,
          slots: this.match.homeTeamIds.map(m => this.pool.getSlot(m)),
          teams: this.match.homeTeamIds.map(m => this.getTeam(m)),
          winner: this.match.winner === 'home',
          map: 'home'
        })
      } else {
        t.push({
          holder: this.match.homeMap,
          map: 'home'
        })
      }
      // away
      if (this.match.awayTeam) {
        t.push({
          slots: [this.match.awayTeam.slot],
          teams: [this.awayTeam],
          winner: this.match.winner === 'away',
          map: 'away',
          seed: this.awaySeed,
          logo: this.useLogos && this.awayTeam && this.awayTeam.logo
        })
      } else if (this.match.awayTeamIds.length) {
        t.push({
          kob: true,
          slots: this.match.awayTeamIds.map(m => this.pool.getSlot(m)),
          teams: this.match.awayTeamIds.map(m => this.getTeam(m)),
          winner: this.match.winner === 'away',
          map: 'away'
        })
      } else {
        t.push({
          holder: this.match.awayMap,
          map: 'away'
        })
      }
      return t
    },
    score () {
      if (this.match.games.length === 1) {
        return this.match.games[0]._winner ? {
          home: this.match.games[0]._winner === 'home' ? 'W' : 'L',
          away: this.match.games[0]._winner === 'home' ? 'L' : 'W'
        } : {
          home: this.match.games[0].home,
          away: this.match.games[0].away
        }
      }
      const show = this.match.homeSetWins || this.match.awaySetWins
      return {
        home: show ? this.match.homeSetWins : '',
        away: show ? this.match.awaySetWins : ''
      }
    },
    useLogos () {
      return this.division && this.division.useLogos
    },
    publicScoring () {
      return this.tournament && this.tournament.publicScoring && !this.blockPublicScoring
    },
    blockPublicScoring () {
      return this.division && this.division.props.includes('blockPublicScoring')
    },
    diffSettings () {
      if (this.pool) {
        if (this.match.isMatch && this.pool && this.pool.setting && this.pool.setting.isMatch) return false
        if (this.match.matchDescription.toLowerCase() === (this.pool.matchDescription ? this.pool.matchDescription.toLowerCase() : '')) return false
        if (this.match.isTiebreak) return true
        if (this.match.isMatch && this.pool && this.pool.setting && !this.pool.setting.isMatch) return false
        return this.match.matchDescription.toLowerCase() !== (this.pool.matchDescription ? this.pool.matchDescription.toLowerCase() : '') || this.match.isTiebreak
      }
      if (this.bracket) {
        const settings = this.match.isWinners ? this.bracket.winnersMatchSettings : this.bracket.losersMatchSettings
        if (!settings) return false
        const d = new PoolPlayOptionDescriptor(settings)
        return this.match.matchDescription.toLowerCase() !== d.description.toLowerCase()
      }
      return false
    },
    matchDescription () {
      const r = this.tournament.props.includes('tbRally') && this.match.isTiebreak
      return `${this.match.matchDescription}${r ? ' - Rally Scoring' : ''}`
    },
    settingSameAsRest () {
      if (!this.settings) return true
      const a = this.settings && new MatchDescriptor({ games: this.settings.gameSettings })
      var r = this.myRoundDesc.filter(f => f !== a.description)
      var b = this.myBracketDesc.filter(f => f !== a.description)
      return { round: r.length === 0, bracket: b.length === 0 }
    },
    myDesc () {
      const a = this.settings && new MatchDescriptor({ games: this.settings.gameSettings, isMatch: this.settings.isMatch })
      return a.description
    },
    myRoundDesc () {
      const a = this.match.isWinners ? this.bracket.winners : this.bracket.losers
      const r = a.find(f => f.number === this.match.round)
      return r.getDesc(this.match.number)
    },
    myBracketDesc () {
      const a = this.match.isWinners ? this.bracket.winners : this.bracket.losers
      return [...new Set(flatten(a.map(m => m.getDesc(this.match.number))))]
    },
    showResetGames () {
      const s = this.getSettings()
      return this.matchAdmin && this.status === 'Complete' && this.editableMatch && this.editableMatch.games.length < s.gameSettings.length
    },
    applyTo () {
      return this.applyToAll ? 'all' : this.applyToFuture ? 'future' : this.applyToRound ? 'round' : false
    }
  },
  methods: {
    unplay () {
      const type = this.bracketCard ? 'bracket' : 'pool'
      this.loading = true
      this.$VBL.post.unplay(this.matchIn.id, type)
        .then(r => {
          this.$emit('match-saved')
        })
        .catch(e => console.log(e.response))
        .finally(() => {
          this.loading = false
          this.cancelEdit()
        })
    },
    edit (w) {
      if (!this.editableMatch) this.editableMatch = new Match(this.$VBL, JSON.stringify(this.matchIn), this.matchIn.type)
      this.winLoss = this.editableMatch.games.filter(g => g._winner).length > 0
      this.editing = true
      if (w && typeof w === 'number') {
        if (w === 3 && !this.editableMatch.startTime) this.editableMatch.startTime = 'now'
        if (w === 5) this.poolRefWindow = this.editableMatch.manualRefTeam ? 2 : 1
        // if (w === 7) this.swapMe = this.swap.teams.length === 1 ? this.swap.teams[0] : null
        if (w === 9) this.editSettings()
        this.window = w
      } else {
        this.$nextTick(() => { this.focusMe() })
      }
    },
    async editSettings () {
      let s = this.matchIn.settings
      if (!s) {
        if (this.matchIn.type === 'bracket') {
          s = (this.matchIn.isWinners ? this.bracket.winnersMatchSettings : this.bracket.losersMatchSettings)
        } else {
          s = this.pool.setting
          // this.loading = true
          // await this.getMatchSettings()
          //   .then(r => { s = r })
          //   .finally(() => { this.loading = false })
        }
      }
      this.settings = new MatchSettingsJs(JSON.parse(JSON.stringify(s)))
    },
    getMatchSettings () {
      return new Promise((resolve, reject) => {
        this.$VBL.tournament.matches.getSettings(this.matchIn.id, this.matchIn.type === 'pool')
          .then(r => {
            resolve(r.data)
          })
      })
    },
    getSettings () {
      let s = this.matchIn.settings
      if (!s) {
        if (this.matchIn.type === 'bracket') {
          s = (this.matchIn.isWinners ? this.bracket.winnersMatchSettings : this.bracket.losersMatchSettings)
        } else {
          s = this.pool.setting
          // this.loading = true
          // await this.getMatchSettings()
          //   .then(r => { s = r })
        }
      }
      return s
    },
    resetGames () {
      const s = this.getSettings()
      if (s) {
        const e = this.editableMatch
        s.gameSettings.forEach((g, i) => {
          if (e.games.length > i) {
            e.games[i].update(g)
          } else {
            e.games.push(new Game(this.$VBL, JSON.parse(JSON.stringify(g))))
          }
        })
      }
    },
    focusMe () {
      if (!this.editAllN && this.$refs.home) {
        this.$refs.home[0].focus()
      }
    },
    cancelEdit () {
      this.editing = false
      this.editableMatch = null
      this.winLoss = this.match.games.filter(g => g._winner).length > 0
      this.swapMe = null
      this.swapWith = null
      this.settings = null
      this.settingsOg = null
      this.askComplete = false
      this.applyToAll = false
      this.applyToRound = false
      this.applyToFuture = false
      this.refFeeder = null
      this.refWL = 1
      if (this.window > 2) this.window = 2
    },
    onSaveClick () {
      this.saveEdit()
    },
    completeMatch () {
      this.games.filter(f => f.status !== 'Complete').forEach(g => {
        g.isFinal = true
      })
      this.saveEdit('asIs')
    },
    saveEdit (asIs) {
      if (this.dirty || asIs === 'asIs') {
        this.loading = true
        const required = ['id', 'poolId', 'bracketId', 'number']
        const exclude = ['games']
        var dto = this.window > 2 ? MinifyDto(this.dto, this.matchIn.dto, required, exclude) : this.dto
        if (this.window === 6 && this.bracketRefWindow === 2 && this.refFeeder) {
          dto.refFeeder = (this.refFeeder * this.refWL)
        }
        console.log(dto)
        this.$VBL.post.matches([dto], false, false, this.applyTo)
          .then(r => { this.$emit('match-saved') })
          .catch(err => console.log(err.response))
          .finally(() => {
            this.loading = false
            this.cancelEdit()
          })
      } else { this.cancelEdit() }
    },
    deleteMe () {
      this.loading = true
      this.$VBL.delete.match(this.matchIn.id)
        .then(r => { this.$emit('match-saved') })
        .catch(err => console.log(err.response))
        .finally(() => {
          this.loading = false
          this.cancelEdit()
        })
    },
    feederWasBye (feederN) {
      const match = this.bracket.matches.find(f => f.number === feederN)
      return match && match.isBye
    },
    feederIsSameBracket (feederN) {
      const match = this.bracket.matches.find(f => f.number === feederN)
      return match && match.isWinners === this.match.isWinners
    },
    feederExists (feederN) {
      const matchI = this.bracket.matches.findIndex(f => f.number === feederN)
      return matchI > -1
    },
    mapRefs () {
      if (this.match && !this.match.refTeam && this.match.refTeamId) {
        this.match.refTeam = this.getTeam(this.match.refTeamId)
      }
      if (this.match && !this.match.manualRefTeam && this.match.manualRefId) {
        this.match.manualRefTeam = this.getTeam(this.match.manualRefId)
      }
    },
    copyToClipboard (text) {
      CopyToClipboard(text)
    },
    checkSetRefresh () {
      if (this.lLock.hasLock && this.lLock.isLocked) return

      const d = moment(this.lLock.dtLock).diff(moment())
      this.timeOut = setTimeout(() => {
        this.refreshKey++
      }, d)
    }
  },
  watch: {
    'lLock.dtLock': 'checkSetRefresh',
    window: function (val) {
      if (val === 3 && !this.matchIn._startTime) {
        this.match.startTime = this.round.dtStart
      }
    },
    editAll: function (val) {
      if (val) {
        this.edit()
      } else {
        this.cancelEdit()
      }
    },
    matchIn: function (val) {
      this.mapRefs()
      if (val && val.games) {
        this.winLoss = this.matchIn.games.filter(g => g._winner).length > 0
      }
    },
    editableMatch: {
      handler: function (val) {
        if (!this.liveScoring) return
        this.saveEdit()
      },
      deep: true
    },
    'match.refTeamId': function (n, o) {
      if (n) this.match.refTeam = this.getTeam(this.match.refTeamId)
    },
    'match.manualRefTeam': function (n, o) {
      if (n) this.match.refTeam = null
    },
    'match.refTeam': function (n, o) {
      if (n) {
        this.match.manualRefTeam = null
      } else {
        if (this.matchIn.type === 'bracket') {
          this.match.refTeam = this.match.refTeamId = null
          return
        }
        if (this.match.refTeamId) this.match.refTeam = this.getTeam(this.match.refTeamId)
      }
    },
    dirtySettings: function (val) {
      if (val) {
        this.editableMatch.settings = this.settings.dto
      } else if (this.editableMatch) {
        this.editableMatch.settings = null
      }
    },
    'settings.dto': function (val) {
      if (val) {
        this.editableMatch.settings = this.settings.dto
      } else if (this.editableMatch) {
        this.editableMatch.settings = null
      }
    },
    dirty: function (val) {
      this.$emit('dirty-change', { n: this.matchIn.number, dirty: val })
    },
    mapRefs () {
      if (this.match && !this.match.refTeam && this.match.refTeamId) {
        this.match.refTeam = this.getTeam(this.match.refTeamId)
      }
      if (this.match && !this.match.manualRefTeam && this.match.manualRefId) {
        this.match.manualRefTeam = this.getTeam(this.match.manualRefId)
      }
    },
    applyToAll: function (v) {
      this.applyToRound = v
      this.applyToFuture = v
    },
    applyToRound: function (n, o) {
      if (o && !n) {
        this.applyToFuture = false
      }
    }
  },
  mounted () {
    this.checkSetRefresh()
    this.mapRefs()
    if (this.editAll) {
      this.edit()
    }
  },
  destroyed () {
    if (this.timeOut) clearTimeout(this.timeOut)
  },
  components: {
    DateTimePicker,
    MatchSettings,
    AddStreamUrlDialog,
    LiveScoringDialog,
    TeamRow,
    LineupAssign,
    TeamSwapper,
    MatchPrint
  }
}
</script>

<style scoped>
.no-border {
  border: none !important;
}
.match-card {
  font-size: 14px;
}
.ref,
.match-card >>> td {
  font-size: 13px;
}
.tiny {
  font-size: x-small;
}
.bordered {
  border: 1px solid rgba(0,0,0,0.12);
}
.footer td {
  height: auto;
  font-size: 11px;
  padding: 0 !important;
}
.t2 td:first-child,
.t2 >>> th:first-child,
.teams-table >>> th:not(:first-child) {
  padding: 0 6px !important;
}
>>> .winner {
  font-weight: 700;
}
.no-anim {
  transition: 0s;
}
@media screen and (max-width: 600px) {
  .t2 td:first-child,
  .t2 >>> th:first-child {
    padding: 0 6px !important;
  }
  .t2 >>> td>div {
    white-space: nowrap;
  }
}
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 1;
  position: absolute;
  width: 100%;
}
.bracket-match {
  font-size: 0.85em;
  line-height: 1.25em;
  min-height: 75px;
}
.bracket-match div {
  min-height: 1.25em;
}
.bracket-team {
  padding: 0.4em;
  background-color: #fff;
  display: flex;
}
.bracket-team.away {
  border-top: 2px solid #eee;
}
.bracket-team .seed {
  font-size: 0.85em;
  width: 1.5em;
  padding-right: 0.25em;
  color: #a3a3a3;
}
.bracket-team .team {
  width: 100%;
}
.bracket-team .score {
  width: 2em;
  text-align: right;
}
.bracket-label {
  font-size: 0.9em;
  margin-left: 0.2em;
}
.bracket-label.bottom {
  margin-top: 0.2em;
}
.waiting {
  color: #BDBDBD;
  font-style: italic;
}
.teams-table >>> td, .teams-table >>> th {
  height: 32px !important;
}
</style>
